export const lexics = {
  button_good: 'Понятно',
  no_data: 'Ничего не найдено',
  access: {
    title: 'Вам недоступен Maven Detailing.',
    description: 'Вы будете переадресованы на страницу Maven ID.',
  },
  api: {
    response: {
      default: {
        success: '',
        error:
          'Соединение с удаленным сервером прервано. Проверьте интернет соединение',
      },
      updateClient: {
        success: 'Мы успешно обновили информацию о клиенте',
        error: {
          default: 'При обновлении клиента прозошла ошибка',
        },
      },
      postCommentsArrangements: {
        success: 'Мы успешно сохранили данные',
        error: 'Не удалось сохранить данные',
      },
      getUser: {
        success: '',
        error: {
          default: '',
        },
      },
      createStatistics: {
        success: '',
        error: {
          default: '',
        },
      },
    },
  },
  client: {
    selects: {
      visit_type: 'Тип визита',
      organization_type: 'Тип организации',
      city: 'Выберите город',
      organization: 'Выберите организацию',
      client: 'Выберите клиента',
      tags: {
        client: 'Выберите тег клиента',
        organisation: 'Выберите тег организации',
        selected: 'Укажите тег',
      },
    },
  },
  presentation: {
    buttons: {
      start: 'Начать визит',
    },
    description: 'Для начала визита заполните поля ниже',
  },
  segmentation: {
    title: 'Сегментация 360',
    comments: {
      agreements: 'Ваши договорённости',
      comment: 'Ваши комментарии',
      warning: 'Вы не сохранили комментарии и договоренности',
    },
    buttons: {
      visit_finish: 'Закончить визит',
    },
    segments: {
      title:
        'DISC — четырехсекторная поведенческая модель для исследования поведения людей в окружающей их среде или в определённой ситуации, рассматривающая стили поведения и предпочтения в поведении.',
      description:
        'При оценке поведения человека выделяют четыре аспекта, говорящих о предпочтениях человека в ассоциациях слов:',
    },
    modals: {
      visit_start: {
        button_start: 'Да, начать визит',
        stats: {
          title: 'Вы действительно хотите начать визит?',
        },
        no_stats: {
          title:
            'Вы действительно хотите начать тестовый визит без записи статистики?',
          description: 'Все данные о визите не будут сохранены',
        },
      },
      visit_finish: {
        title: 'Вы действительно хотите закончить визит?',
      },
    },
  },
  test: {
    result: {
      description: 'Если вы не уверены в результате, можете пройти ещё раз',
      retake: 'Пройти заново',
      finish: 'Завершить',
      invalid: {
        title: 'Хм... результат получился неоднозначным',
        text: 'Исходя из ваших ответов, не удалось определить психотип врача. Попробуйте ответить еще раз, нажав кнопку внизу. Или пройдите опрос позже. А пока можете вернуться в раздел сегментации, нажав на кнопку в левом верхнем углу.',
      },
    },
  },
  statistics: {
    title: 'Статистика по пройденной презентации',
    presentation: 'Время, проведенное на презентации (визите)',
    slides: 'Посмотрено слайдов',
    time: 'Среднее время на слайд',
  },
};
