import { createToastError } from 'ui-kit/alert-info/controls';

import { lexics } from 'data/lexics';

import { BackendError } from 'types/api';

export const redirectToErrorPage = ({ error = '', status }: BackendError) => {
  const isNoInternet = error && /Network Error|Failed to fetch/.test(error);

  const isCheck = Number(status) >= 400;

  if (isNoInternet) {
    createToastError(lexics.api.response.default.error);
  }

  if (isCheck) {
    switch (status) {
      case 401: {
        window.location.assign(
          `${process.env.NEXT_PUBLIC_ID_URL}?redirect=${window.location.host}`,
        );

        break;
      }

      case 404: {
        window.location.assign(`${window.location.origin}/404`);

        break;
      }

      case 403: {
        window.location.assign(`${window.location.origin}/403`);

        break;
      }

      case 503: {
        window.location.assign(`${window.location.origin}/503`);

        break;
      }

      default: {
        window.location.assign(`${window.location.origin}/unknown`);
      }
    }
  }
};
