import * as React from 'react';

import styles from './layout-wrapper.module.scss';

const Layout: React.FC = (props) => {
  const { children } = props;

  return <div className={styles.layout}>{children}</div>;
};

export default Layout;
