import * as React from 'react';

import { Nullable } from 'maven-ui-kit/core/types';

import { StatisticsState } from 'reducers/statisticsSlice';
import { useGetUserQuery } from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

import Footer from 'components/footer';
import Header from 'components/header';
import Modals from 'components/modals';
import NoAccess from 'components/noAccess';
import Preloader from 'components/preloader';

import { LocalKey } from 'constants/local-storage';

import { getSlidesLink } from 'utils/link';

import { presentationPages } from './const';

import styles from './layout.module.scss';

const Layout: React.FC = (props) => {
  const { isLoading, error } = useGetUserQuery();

  const { getValue: getLocalStatistics } = useLocalStorage<StatisticsState>(
    LocalKey.statistics,
  );

  const mainRef = React.useRef<Nullable<HTMLElement>>(null);

  const { pathname, push } = useCustomRouter();

  const { children } = props;

  const isHomePage = pathname.includes('home');
  const noAccess = error && 'status' in error && error.status === 403;

  const checkRemovingStatistics = async () => {
    const localStatistics = getLocalStatistics();

    if (localStatistics) {
      const {
        clientInformation: { presentationId },
      } = localStatistics;

      push(getSlidesLink(presentationId));
    }
  };

  React.useEffect(() => {
    const isPresentationPage = presentationPages.some((presentationPage) =>
      presentationPage.includes(pathname),
    );
    if (!isPresentationPage) {
      checkRemovingStatistics();
    }

    const { current } = mainRef;
    if (current) current.scrollTo(0, 0);
  }, [pathname]);

  if (isLoading) {
    return <Preloader />;
  }

  if (noAccess) {
    return <NoAccess />;
  }

  return (
    <>
      {isHomePage && <Header />}

      <main ref={mainRef} className={styles.innerWrapper}>
        {children}
      </main>

      {isHomePage && <Footer />}

      <Modals />
    </>
  );
};

export default Layout;
