import uuid from 'react-uuid';

import SortContainer from 'components/sort-container';
import {
  clientButtons,
  getDefaultSortQueryParams,
  organizationButtons,
  presentationButtons,
} from 'components/sort-container/const';
import { SortQuery } from 'components/sort-container/type';

import { ClientIcon, OrganizationIcon, PresentationIcon } from 'ui-kit/icons';

import { Links } from 'types/links';

interface Tab {
  title: string;
  sortContainer?: JSX.Element;
  icon: JSX.Element;
  href: Links | string;
  query?: SortQuery;
}

export const NAVIGATION_TABS: Tab[] = [
  {
    title: 'Презентации',
    icon: <PresentationIcon />,
    query: getDefaultSortQueryParams(presentationButtons),
    href: Links.home,
    sortContainer: (
      <SortContainer
        key={uuid()}
        initialSort={presentationButtons[0].sortName}
        buttons={presentationButtons}
      />
    ),
  },
  {
    title: 'Клиенты',
    icon: <ClientIcon />,
    href: Links.clients,
    query: getDefaultSortQueryParams(clientButtons),
    sortContainer: (
      <SortContainer
        key={uuid()}
        initialSort={clientButtons[0].sortName}
        buttons={clientButtons}
      />
    ),
  },
  {
    title: 'Организации',
    icon: <OrganizationIcon />,
    href: Links.organizations,
    query: getDefaultSortQueryParams(organizationButtons),
    sortContainer: (
      <SortContainer
        key={uuid()}
        initialSort={organizationButtons[0].sortName}
        buttons={organizationButtons}
      />
    ),
  },
];
