import { FC } from 'react';

import cn from 'classnames';

import usePsychotype from 'hooks/usePsychotype.hook';

import { SegmentIcon } from 'ui-kit/icons';

import {
  PsychotypeInformationContainer,
  PsychotypeInformationProps,
} from './type';

import styles from './information.module.scss';

const PsychotypeInformation: FC<PsychotypeInformationProps> = (props) => {
  const { psychotypes, container } = props;

  const information = usePsychotype(psychotypes);

  if (!information) return null;

  const { colorName, indicator, getDescription } = information;

  const title = {
    [PsychotypeInformationContainer.modal]: `${colorName} поведенческий тип`,
    [PsychotypeInformationContainer.test]: `Вы определили психотип врача как ${colorName}`,
  };

  return (
    <section className={cn('flex-column flex-align_center', styles.container)}>
      <h2 className={styles.title}>{title[container]}</h2>
      <SegmentIcon psychotypes={psychotypes} />
      <p className={styles.indicator}>{indicator}</p>
      {getDescription('text_center')}
    </section>
  );
};

export default PsychotypeInformation;
