export const SegmentationIcon = () => (
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.667 15.555c0 6.137-4.975 11.112-11.112 11.112-6.136 0-11.11-4.975-11.11-11.112 0-6.136 4.974-11.11 11.11-11.11m11.112 11.11c0-6.136-4.975-11.11-11.112-11.11m11.112 11.11H16.322c-.256 0-.767-.153-.767-.766V4.444"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

export const SegmentationArrangementIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
    <path
      d="M13.76 11.206a.5.5 0 0 0 .642.766l-.643-.766zm.642-8.256a.5.5 0 0 0-.643.766l.643-.766zm-1.754 1.62a.5.5 0 1 0-.709.705l.71-.705zm-.726 4.866a.5.5 0 0 0 .704.71l-.704-.71zM1.5 15.333a.5.5 0 1 0 1 0h-1zm9.935 0a.5.5 0 1 0 1 0h-1zM15.5 7.461a4.873 4.873 0 0 1-1.74 3.745l.642.766a5.873 5.873 0 0 0 2.098-4.51h-1zm-1.74-3.745a4.873 4.873 0 0 1 1.74 3.745h1a5.873 5.873 0 0 0-2.098-4.51l-.643.765zm-1.821 1.559a2.93 2.93 0 0 1 .851 2.072h1a3.93 3.93 0 0 0-1.142-2.777l-.709.705zm.851 2.072a2.93 2.93 0 0 1-.868 2.09l.704.71a3.93 3.93 0 0 0 1.164-2.8h-1zM9.63 7.12c-.001 1.493-1.197 2.694-2.662 2.694v1c2.027 0 3.661-1.659 3.661-3.694h-1zM6.967 9.813c-1.465 0-2.662-1.201-2.662-2.694h-1c0 2.035 1.635 3.694 3.662 3.694v-1zM4.306 7.12c0-1.493 1.197-2.695 2.662-2.695v-1c-2.027 0-3.662 1.66-3.662 3.695h1zm2.662-2.695c1.465 0 2.661 1.202 2.661 2.695h1c0-2.036-1.634-3.695-3.661-3.695v1zM2.5 15.334c0-.448.135-1.04.52-1.51.367-.45 1.008-.843 2.141-.843v-1c-1.395 0-2.335.5-2.916 1.21-.564.69-.745 1.525-.745 2.142h1zm9.935 0c0-.618-.18-1.452-.745-2.143-.58-.71-1.52-1.21-2.916-1.21v1c1.134 0 1.774.393 2.142.843.384.47.52 1.062.52 1.51h1zm-3.66-3.353H5.16v1h3.613v-1z"
      fill="currentColor"
    />
  </svg>
);

export const SegmentationCommentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
    <path
      d="M5.456 12.528c-.91.715-2.89 1.278-3.554 1.377.19-.496 1.04-1.786.854-2.976h0l-.01-.039a5.162 5.162 0 0 1-1.413-3.533C1.333 4.4 3.881 2 7.023 2c3.143 0 5.025 1.919 5.42 3.493h0l-6.987 7.035zm0 0c.5.122 1.025.187 1.567.187-.536 0-1.055-.07-1.547-.2h0l-.02.013zm7.245-3.147v3.935zm1.97 1.968h-3.94zm1.996 0c0 2.2-1.787 3.984-3.99 3.984a3.988 3.988 0 0 1-3.99-3.984c0-2.201 1.786-3.985 3.99-3.985a3.988 3.988 0 0 1 3.99 3.985z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="square"
      fill="none"
    />
  </svg>
);
