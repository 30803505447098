import { FC } from 'react';

import { ParsedUrlQueryInput } from 'querystring';

import { AllSort } from 'store/api/type';

import {
  initialOrder,
  SortOrder,
  useCustomRouter,
} from 'hooks/useCustomRouter.hook';

import InputSearch from 'ui-kit/input/components/input-search';

import SortItem from './components/sort-item';
import { SortContainerProps } from './type';

import styles from './sort-container.module.scss';

const SortContainer: FC<SortContainerProps> = (props) => {
  const { buttons } = props;

  const router = useCustomRouter();

  const { sort, order, text, type } = router.query;

  const handleChangeSort = (sort: AllSort) => () => {
    const query: ParsedUrlQueryInput = {
      sort,
      order: initialOrder,
      ...(text && { text }),
    };

    router.push({
      query,
    });
  };

  const handleChangeOrder = () => {
    const order =
      router.query.order === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

    const query: ParsedUrlQueryInput = {
      ...(type && { type }),
      ...(sort && { sort }),
      order,
      ...(text && { text }),
    };

    router.push({
      query,
    });
  };

  const handleSearchCallback = (text: string) => {
    const query: ParsedUrlQueryInput = {
      ...(type && { type }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(text && { text }),
    };

    router.push({
      query,
    });
  };

  return (
    <div className={styles.container}>
      <ul className={styles.sortList}>
        {buttons.map((button) => {
          const { text, orderType, sortName } = button;

          const isOrderRevert = order === SortOrder.desc && sortName === sort;
          const isActive = sortName === sort && !type;
          const isOrderDisabled = Boolean(sortName !== sort || type);

          return (
            <SortItem
              key={text}
              isActive={isActive}
              isOrderRevert={isOrderRevert}
              isOrderDisabled={isOrderDisabled}
              orderType={orderType}
              sortName={sortName}
              text={text}
              onClickSort={handleChangeSort(sortName)}
              onClickOrder={handleChangeOrder}
            />
          );
        })}
      </ul>

      <InputSearch handleSearchCallback={handleSearchCallback} />
    </div>
  );
};

export default SortContainer;
