import Link from 'next/link';
import * as React from 'react';
import uuid from 'react-uuid';

import cn from 'classnames';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import { ProfileIcon } from 'ui-kit/icons/ProfileIcon';

import { NAVIGATION_TABS } from 'data';

import { Links } from 'types/links';

import styles from './footer.module.scss';

const Footer: React.FC = () => {
  const { pathname } = useCustomRouter();

  return (
    <footer className={styles.tablist}>
      {NAVIGATION_TABS.map(({ title, icon, href, query }) => {
        const isSelected =
          pathname === href || (href !== Links.home && pathname.includes(href));

        return (
          <Link
            key={uuid()}
            href={{
              pathname: href,
              query,
            }}>
            <a
              className={cn(styles.tab, {
                [styles.tab_selected]: isSelected,
              })}>
              {icon}
              {title}
            </a>
          </Link>
        );
      })}
      <a
        className={styles.tab}
        href={`${process.env.NEXT_PUBLIC_ID_URL}/home?from=${window.location.host}`}>
        <ProfileIcon />
        Профиль
      </a>
    </footer>
  );
};

export default Footer;
