import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';

import cn from 'classnames';

import mavenLogoDark from 'public/static/images/maven-detaling-logo-dark.svg';

import { Links } from 'types/links';

import styles from './logo.module.scss';

const Logo: React.FC = () => {
  return (
    <Link href={Links.home}>
      <div className={cn('pointer flex-row', styles.logo)}>
        <Image
          src={mavenLogoDark}
          alt="Maven Detailing"
          width={158}
          height={38}
          unoptimized
        />
      </div>
    </Link>
  );
};

export default Logo;
