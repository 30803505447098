import { Nullable } from 'maven-ui-kit/core/types';

import { AllPsychotype } from 'ui-kit/icons/types';

import psychotypeInformation, { Information } from 'data/psychotypes';

const usePsychotype = (psychotypes: AllPsychotype[]): Nullable<Information> => {
  if (psychotypes.length > 2 || !psychotypes.length) return null;

  if (psychotypes.length === 1) {
    return psychotypeInformation[psychotypes[0]];
  }

  const isPsychotypeD = psychotypes.some(
    (psychotype) => psychotype === AllPsychotype.D,
  );
  const isPsychotypeI = psychotypes.some(
    (psychotype) => psychotype === AllPsychotype.I,
  );
  const isPsychotypeS = psychotypes.some(
    (psychotype) => psychotype === AllPsychotype.S,
  );
  const isPsychotypeC = psychotypes.some(
    (psychotype) => psychotype === AllPsychotype.C,
  );

  if (isPsychotypeD && isPsychotypeI) {
    return psychotypeInformation[AllPsychotype.DI];
  }

  if (isPsychotypeD && isPsychotypeS) {
    return psychotypeInformation[AllPsychotype.DS];
  }

  if (isPsychotypeD && isPsychotypeC) {
    return psychotypeInformation[AllPsychotype.DC];
  }

  if (isPsychotypeI && isPsychotypeS) {
    return psychotypeInformation[AllPsychotype.IS];
  }

  if (isPsychotypeC && isPsychotypeS) {
    return psychotypeInformation[AllPsychotype.CS];
  }

  if (isPsychotypeC && isPsychotypeI) {
    return psychotypeInformation[AllPsychotype.CI];
  }

  return null;
};

export default usePsychotype;
