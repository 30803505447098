import * as React from 'react';

import * as Sentry from '@sentry/nextjs';

import ErrorBoundaryFallback from 'components/error-boundary-fallback';

const AlertBoundary: React.FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      // @ts-ignore
      fallback={ErrorBoundaryFallback}
      beforeCapture={(scope) => {
        scope.setLevel('fatal');
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
export default AlertBoundary;
