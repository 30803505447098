export const SaveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none">
    <path
      d="M1.5 2.5C1.5 2.22386 1.72386 2 2 2H15.5858C15.7184 2 15.8456 2.05268 15.9393 2.14645L18.3536 4.56066C18.4473 4.65443 18.5 4.7816 18.5 4.91421V18.5C18.5 18.7761 18.2761 19 18 19H2C1.72386 19 1.5 18.7761 1.5 18.5V2.5Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M5 2.5V7.5C5 8.05228 5.44772 8.5 6 8.5H12C12.5523 8.5 13 8.05228 13 7.5V2.5"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M5 18.5V13.5C5 12.9477 5.44772 12.5 6 12.5H14C14.5523 12.5 15 12.9477 15 13.5V18.5"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path d="M10 3.5V6.5" stroke="currentColor" strokeLinejoin="round" />
  </svg>
);
