export const OrganizationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 29.667H8v2h20v-2ZM6.333 27.928v-11.64h-2v11.64h2Zm0-11.64v-3.423h-2v3.423h2Zm23.333-3.423v3.423h2v-3.423h-2Zm0 3.423v11.64h2v-11.64h-2Zm-24.333 1h25.333v-2H5.333v2ZM8 11.126h4.666v-2H8v2Zm5.666-1V8.072h-2v2.054h2Zm1.667-3.793h5.333v-2h-5.333v2Zm-2.667 4.793h10.667v-2H12.666v2Zm10.667 0H28v-2h-4.667v2Zm-1-3.054v2.054h2V8.072h-2Zm-1.667-1.739c.896 0 1.667.754 1.667 1.74h2c0-2.04-1.617-3.74-3.667-3.74v2ZM8 29.667c-.896 0-1.667-.753-1.667-1.739h-2c0 2.04 1.617 3.739 3.667 3.739v-2Zm5.666-21.595c0-.985.771-1.739 1.667-1.739v-2c-2.05 0-3.667 1.7-3.667 3.74h2ZM28 31.667c2.05 0 3.666-1.7 3.666-3.739h-2c0 .986-.77 1.739-1.666 1.739v2Zm3.666-18.802c0-2.04-1.616-3.739-3.666-3.739v2c.895 0 1.666.753 1.666 1.739h2Zm-25.333 0c0-.986.771-1.739 1.667-1.739v-2c-2.05 0-3.667 1.7-3.667 3.739h2Z"
      fill="#818BA7"
    />
  </svg>
);

export const OrganizationTitleIcon = () => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6363 8.41356C10.6363 8.92024 10.2025 9.33099 9.66732 9.33099C9.13216 9.33099 8.69833 8.92024 8.69833 8.41356C8.69833 7.90688 9.13216 7.49613 9.66732 7.49613C10.2025 7.49613 10.6363 7.90688 10.6363 8.41356Z"
      fill="currentColor"
    />
    <path
      d="M9.66732 20.8906L9.36208 21.5757C9.56796 21.6674 9.80418 21.6617 10.0054 21.5601L9.66732 20.8906ZM2.08398 8.78053C2.08398 4.87538 5.43999 1.64062 9.66732 1.64062V0.140625C4.6899 0.140625 0.583984 3.97073 0.583984 8.78053H2.08398ZM9.66732 1.64062C13.8946 1.64062 17.2507 4.87538 17.2507 8.78053H18.7507C18.7507 3.97073 14.6447 0.140625 9.66732 0.140625V1.64062ZM17.2507 8.78053C17.2507 9.68706 16.9556 10.7688 16.4246 11.9362C15.8974 13.0955 15.1584 14.2941 14.3219 15.4206C12.629 17.7007 10.6296 19.5645 9.32926 20.2211L10.0054 21.5601C11.612 20.7488 13.7793 18.6677 15.5263 16.3148C16.4098 15.1248 17.209 13.8348 17.7901 12.5572C18.3674 11.2877 18.7507 9.9841 18.7507 8.78053H17.2507ZM0.583984 8.78053C0.583984 11.1321 1.95903 13.9534 3.65699 16.3065C5.35875 18.665 7.53688 20.7625 9.36208 21.5757L9.97255 20.2055C8.50318 19.5509 6.51464 17.7034 4.87339 15.4288C3.22832 13.149 2.08398 10.6492 2.08398 8.78053H0.583984ZM9.88631 8.41356C9.88631 8.46792 9.82743 8.58099 9.66732 8.58099V10.081C10.5775 10.081 11.3863 9.37257 11.3863 8.41356H9.88631ZM9.66732 8.58099C9.5072 8.58099 9.44833 8.46792 9.44833 8.41356H7.94833C7.94833 9.37257 8.75711 10.081 9.66732 10.081V8.58099ZM9.44833 8.41356C9.44833 8.3592 9.5072 8.24613 9.66732 8.24613V6.74613C8.75711 6.74613 7.94833 7.45455 7.94833 8.41356H9.44833ZM9.66732 8.24613C9.82743 8.24613 9.88631 8.3592 9.88631 8.41356H11.3863C11.3863 7.45455 10.5775 6.74613 9.66732 6.74613V8.24613Z"
      fill="currentColor"
    />
  </svg>
);
