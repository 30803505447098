import { components, NoticeProps } from 'react-select';

import { Option } from '../type';

const { NoOptionsMessage } = components;

const NoOptionsMessageComponent = (props: NoticeProps<Option>) => (
  <NoOptionsMessage {...props}>
    <span className="custom-css-class">Нет результатов</span>
  </NoOptionsMessage>
);

export default NoOptionsMessageComponent;
