import type { AppProps } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import { Provider } from 'react-redux';

import { Provider as MavenProvider } from 'maven-ui-kit/core';

import store from 'store';

import AlertBoundary from 'components/alert-boundary';
import Layout from 'components/layout';
import LayoutWrapper from 'components/layout-wrapper';

import AlertInfo from 'ui-kit/alert-info';

import 'styles/fonts.scss';
import 'styles/global.scss';

interface AppPropsExtends extends AppProps {
  Component: AppProps['Component'] & { Layout?: React.FC };
}

const App = ({ Component, pageProps }: AppPropsExtends) => {
  return (
    <React.Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
        />
        <meta name="apple-mobile-web-app-title" content="maven.detailing" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="icon" href="/48x48.png" sizes="48x48" type="image/png" />
        <link rel="icon" href="/16x16.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/120x120.png" />
        <link rel="apple-touch-icon" href="/152x152.png" sizes="152x152" />
        <link rel="apple-touch-icon" href="/167x167.png" sizes="167x167" />
      </Head>
      <MavenProvider>
        <LayoutWrapper>
          <AlertBoundary>
            <Provider store={store}>
              <Layout>
                <AlertInfo />
                <Component {...pageProps} />
              </Layout>
            </Provider>
          </AlertBoundary>
        </LayoutWrapper>
      </MavenProvider>
    </React.Fragment>
  );
};

export default App;
