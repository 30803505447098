import { Nullable } from 'maven-ui-kit/core/types';

import { ColorsType } from 'types/organization';

export const constructCustomStyles = (
  gradient: string,
  colors: Nullable<ColorsType>,
) => {
  document.documentElement.style.setProperty('--gradient', `${gradient}`);
  document.documentElement.style.setProperty(
    '--main-gradient',
    `var(--gradient, var(--_main-gradient))`,
  );
  if (colors) {
    Object.entries(colors).map(([color, value]) => {
      document.documentElement.style.setProperty(`--${color}`, `${value}`);
      document.documentElement.style.setProperty(
        `--${color}-rgb`,
        String(hexToRgb(value)),
      );
    });
  }
};

const hexToRgb = (hex: string) => {
  return hex
    ?.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b,
    )
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));
};

export const getWindowHeight = () => {
  const body = document.body;
  const html = document.documentElement;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );

  return height;
};
