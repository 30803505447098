import Image from 'next/image';
import { FC } from 'react';
import uuid from 'react-uuid';

import segmentationFullIcon from 'public/static/images/segmentation_full.svg';

import { getLetters } from 'components/psychotype/helper';

import { SegmentIcon } from 'ui-kit/icons';

import { lexics } from 'data/lexics';
import { allPsychotypes } from 'data/psychotypes';

import styles from './information-initial.module.scss';

const PsychotypeInformationInitial: FC = () => {
  return (
    <section className="flex-column flex-align_center">
      <div className={styles.segmentationTop}>
        <div>
          <Image
            src={segmentationFullIcon}
            width={80}
            height={80}
            unoptimized
          />
        </div>

        <div className={styles.segmentationTop_information}>
          <span className={styles.segmentationTop_title}>
            {lexics.segmentation.segments.title}
          </span>
          <span className={styles.segmentationTop_text}>
            {lexics.segmentation.segments.description}
          </span>
        </div>
      </div>

      <ul className={styles.segmentationList}>
        {allPsychotypes.map((psychotype) => {
          const { psychotypes, indicator, getDescription } = psychotype;

          return (
            <li key={uuid()} className={styles.segmentationItem}>
              <div className={styles.segmentationItem_top}>
                <SegmentIcon
                  psychotypes={psychotypes}
                  classnames={styles.fragments_icon}
                />
                <span className={styles.segmentationItem_letter}>
                  {getLetters(psychotypes)}
                </span>
              </div>
              <div className={styles.segmentationItem_information}>
                <span className={styles.segmentationItem_title}>
                  {indicator}
                </span>

                {getDescription()}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default PsychotypeInformationInitial;
