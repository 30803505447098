import * as React from 'react';

import cn from 'classnames';

import { AccentIconProps } from './type';

import styles from './accent-icon.module.scss';

const AccentIcon: React.FC<AccentIconProps> = (props) => {
  const { icon, className } = props;

  return <div className={cn(styles.wrapper, className)}>{icon}</div>;
};

export default AccentIcon;
