import Image from 'next/image';
import { components, DropdownIndicatorProps } from 'react-select';

import iconOption from 'public/static/images/select-icon.svg';

import { Option } from '../type';

const { DropdownIndicator } = components;

const DropdownIndicatorComponent = (props: DropdownIndicatorProps<Option>) => (
  <DropdownIndicator {...props}>
    <Image
      src={iconOption}
      width={12}
      height={12}
      unoptimized
      alt="icon-select"
    />
  </DropdownIndicator>
);

export default DropdownIndicatorComponent;
