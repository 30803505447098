import { initialOrder } from 'hooks/useCustomRouter.hook';

import { ButtonSort, SortQuery } from './type';

export const presentationButtons: ButtonSort[] = [
  {
    text: 'По дате обновления',
    sortName: 'update',
    orderType: 'date',
  },
  {
    text: 'По названию',
    sortName: 'name',
    orderType: 'text',
  },
];

export const clientButtons: ButtonSort[] = [
  {
    text: 'По имени',
    sortName: 'name',
    orderType: 'text',
  },
  {
    text: 'По городу',
    sortName: 'city',
    orderType: 'text',
  },
  {
    text: 'По организации',
    sortName: 'organisation',
    orderType: 'text',
  },
];

export const organizationButtons: ButtonSort[] = [
  {
    text: 'По имени',
    sortName: 'name',
    orderType: 'text',
  },
  {
    text: 'По городу',
    sortName: 'city',
    orderType: 'text',
  },
  {
    text: 'Тип организации',
    sortName: 'type',
    orderType: 'text',
  },
];

export const getDefaultSortQueryParams = (
  buttonSort: ButtonSort[],
): SortQuery => {
  return {
    sort: buttonSort[0].sortName,
    order: initialOrder,
  };
};
