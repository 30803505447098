import { FC } from 'react';

import { TestIconProps } from 'components/test/type';

import TEST_DATA from 'data/test';

export const TestIcon: FC<TestIconProps> = (props) => {
  const { numberSection, numberQuestion } = props;

  const { icon, questions } = TEST_DATA[numberSection - 1];

  if (!numberQuestion) {
    return icon;
  }

  return questions[numberQuestion - 1].icon;
};
