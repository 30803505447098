export interface NotificationProps {
  header: string;
  message: string;
}
export enum AlertType {
  success = 'success',
  error = 'error',
  info = 'info',
}

export interface CreateToastParams {
  header?: string;
}
