import * as React from 'react';
import uuid from 'react-uuid';

import ModalsPresentations from './components/presentations';

const AllModals: React.FC = () => {
  const modals = [ModalsPresentations];

  return (
    <React.Fragment>
      {modals.map((modal) => {
        return modal.map((Modal) => {
          return <Modal key={uuid()} />;
        });
      })}
    </React.Fragment>
  );
};

export default AllModals;
