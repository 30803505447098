import { FC } from 'react';

export const StatisticsClockIcon: FC = () => (
  <svg width="33" height="33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="16.5"
      cy="16.5"
      r="15.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M16.5 3.5a13 13 0 0 1 11.686 18.695"
      stroke="currentColor"
      strokeWidth="7"
    />
    <path
      d="M16.5 6.457v11.478l8.25 2.51M16.5 29.538v2.744M32.283 16.5h-2.745M3.463 16.5H.718M27.66 27.66l-1.94-1.94M7.281 7.28l-1.94-1.94M5.34 27.66l1.94-1.94M25.719 7.281l1.94-1.94"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const StatisticsSlidesIcon: FC = () => (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1"
      y="8"
      width="24"
      height="15"
      rx="2"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M3 7.5V7a3 3 0 0 1 3-3h19a3 3 0 0 1 3 3v11a2.5 2.5 0 0 1-2.5 2.5"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M28 17.5a2.5 2.5 0 0 0 2.5-2.5V4a3 3 0 0 0-3-3h-19a3 3 0 0 0-3 3M3 9.75h16.5M3 11.5h10.5M3 14h3.5M3 15.5h6M3 18.5h6"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path stroke="currentColor" strokeWidth="2" d="M16.5 13.5H23V21h-6.5z" />
  </svg>
);

export const StatisticsTimeIcon: FC = () => (
  <svg width="34" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 7.5h14a3 3 0 0 1 3 3V21a3 3 0 0 1-3 3H10a3 3 0 0 1-3-3v-5.5M15 10h11M14 11.5h6M11.5 14H13M9.5 15.5h6M9.5 18.5h6"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path stroke="currentColor" strokeWidth="2" d="M23 13.5h6.5V21H23z" />
    <circle cx="7" cy="7" r="6" stroke="currentColor" strokeWidth="2" />
    <path
      d="M7 2.74v4.869L9.25 5.25M7 12.531v1.165M7 .212v1.165M13.695 7h-1.164M1.469 7H.304M11.734 11.734l-.823-.823M3.088 3.089l-.824-.824M2.266 11.734l.823-.823M10.91 3.089l.824-.824"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
