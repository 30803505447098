import { UrlObject } from 'url';

import { dynamicField, dynamicLinks, Names } from 'types/links';

type NamePage = keyof typeof dynamicLinks;

const getLink =
  (namePage: NamePage) =>
  (id: string): UrlObject => {
    const pathname = dynamicLinks[namePage];

    return {
      pathname,
      query: {
        [dynamicField]: id,
      },
    };
  };

export const getClientLink = getLink(Names.client);
export const getOrganizationLink = getLink(Names.organization);
export const getSegmentationLink = getLink(Names.segmentation);
export const getPresentationLink = getLink(Names.presentation);
export const getSlidesLink = getLink(Names.slides);
