import { ReactNode } from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

import { VariantModal } from '../type';
import { variants } from './variants';

import styles from './backdrop.module.scss';

type BackdropProps = {
  onClick: () => void;
  children: ReactNode;
  className?: string;
};

const Backdrop: React.FC<BackdropProps> = ({
  onClick,
  children,
  className,
}: BackdropProps) => (
  <motion.div
    className={cn(styles.backdrop, className)}
    onClick={onClick}
    variants={variants}
    initial={VariantModal.closed}
    animate={VariantModal.open}
    exit={VariantModal.closed}>
    {children}
  </motion.div>
);

export default Backdrop;
