import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { handleHideModal, ModalsState } from 'reducers/modals';
import { RootState } from 'store';

import Backdrop from './backdrop';
import { ModalProps, VariantModal } from './type';
import { variants } from './variants';

import styles from './styles.module.scss';

const Modal: React.FC<ModalProps> = (props) => {
  const { name, onClose, children, className } = props;

  useUnmount(() => {
    handleCloseModal();
  });

  const { modals } = useSelector<RootState, ModalsState>(
    ({ modals }) => modals,
  );

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(handleHideModal(name));

    if (onClose) {
      onClose();
    }
  };

  const isOpen = modals[name];

  const classes = cn(styles.content, className);

  return (
    <AnimatePresence>
      {isOpen && (
        <Backdrop onClick={handleCloseModal}>
          <motion.div
            className={styles.modal}
            variants={variants}
            initial={VariantModal.closed}
            animate={VariantModal.open}
            exit={VariantModal.closed}>
            <div className={classes} onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>
  );
};

export default Modal;
