import { dynamicLinks, Links } from 'types/links';

export const testPages: string[] = [Links.test, Links.test_result];

export const presentationPages: string[] = [
  dynamicLinks.presentation,
  dynamicLinks.segmentation,
  dynamicLinks.slides,
  ...testPages,
];
