import { CSSProperties, FC } from 'react';

import cn from 'classnames';

import { IEllipsisProps } from './types';

import styles from './ellipsis.module.scss';

const ClampedEllipsis: FC<IEllipsisProps> = ({
  children,
  className,
  style,
  lineClamp,
  maxWidth,
}) => {
  const classes = cn(styles['fl-ellipsis'], className);

  const styleText = {
    ...style,
    '--line-clamp-num': lineClamp,
  } as CSSProperties;

  const styleContainer = {
    '--max-width-container': `${maxWidth}px`,
  } as CSSProperties;

  return (
    <div className={styles.container} style={styleContainer}>
      <span className={classes} style={styleText}>
        {children}
      </span>
    </div>
  );
};

export default ClampedEllipsis;
