import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationType } from 'types/organization';

export type OrganizationState = Pick<OrganizationType, 'name' | 'logos'>;

const initialState: OrganizationState = {
  name: null,
  logos: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationInformation(
      state,
      { payload: organisation }: PayloadAction<OrganizationType>,
    ) {
      const { name, logos } = organisation;

      state.name = name;
      state.logos = logos;
    },
  },
});

export const { setOrganizationInformation } = organizationSlice.actions;

export default organizationSlice.reducer;
