import * as React from 'react';

import cn from 'classnames';

import { useLazyGetClientOrganisationTypeAllQuery } from 'store/api';
import { AllSort } from 'store/api/type';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import SortButton from 'ui-kit/order-button';
import Select from 'ui-kit/select';

import { getTypeOptions } from 'utils/type';

import { SortItemProps } from './type';

import styles from './sort-item.module.scss';

const SortItem: React.FC<SortItemProps> = (props) => {
  const {
    isActive,
    isOrderRevert,
    isOrderDisabled,
    text,
    onClickSort,
    onClickOrder,
    orderType,
    sortName,
  } = props;

  const { push, query } = useCustomRouter();

  const [getClientOrganisation, { data: typesOptions }] =
    useLazyGetClientOrganisationTypeAllQuery({
      selectFromResult: (response) => {
        const { data } = response;

        const options = getTypeOptions(data);

        return {
          ...response,
          data: options,
        };
      },
    });

  const handleSelectChange = (queryField: AllSort) => (newValue: string) => {
    const { text = '' } = query;

    const _query: Record<string, string> = {
      [queryField]: query[queryField] === newValue ? '' : newValue,
    };

    if (text) {
      _query.text = text;
    }

    push({
      query: _query,
    });
  };

  React.useEffect(() => {
    if (sortName === 'type') {
      getClientOrganisation({});
    }
  }, [sortName]);

  if (sortName === 'type') {
    const type = query[sortName];

    const defaultValue = typesOptions.find(({ value }) => value === type);

    return (
      <li className={styles.item}>
        <Select
          key={query[sortName]}
          defaultValue={defaultValue}
          placeholder={text}
          onChange={handleSelectChange(sortName)}
          options={typesOptions}
          isSort
        />
      </li>
    );
  }

  const classNameItem = cn(
    'flex-row flex-align_center flex-justify_sb pointer',
    styles.item,
    styles.sortItem,
    {
      [styles.sortItem_active]: isActive,
    },
  );

  const onClickItem = isActive ? onClickOrder : onClickSort;

  return (
    <li onClick={onClickItem} className={classNameItem}>
      <span className={styles.sortButton}>{text}</span>

      <SortButton
        isRevert={isOrderRevert}
        type={orderType}
        disabled={isOrderDisabled}
      />
    </li>
  );
};

export default SortItem;
