import { AllPsychotype } from 'ui-kit/icons/types';

export interface PsychotypeInformationProps {
  psychotypes: AllPsychotype[];
  isShowTitle?: boolean;
  container: PsychotypeInformationContainer;
}

export enum PsychotypeInformationContainer {
  modal = 'modal',
  test = 'test',
}
