import { components, OptionProps } from 'react-select';

import { Option } from '../type';

import styles from '../select.module.scss';

const { Option } = components;

const OptionComponent = (props: OptionProps<Option>) => (
  <Option className={styles.option} {...props} />
);

export default OptionComponent;
