import { FC } from 'react';

import cn from 'classnames';

import AccentIcon from 'ui-kit/accent-icon';
import ClampedEllipsis from 'ui-kit/clamped-ellipsis';

import { AccentIconTitleProps } from './types';

import styles from './accent-icon-title.module.scss';

const AccentIconTitle: FC<AccentIconTitleProps> = (props) => {
  const { children, icon, imageSrc, className, titleStyle } = props;

  return (
    <div className={styles.wrapper}>
      {icon ? <AccentIcon icon={icon} className={styles.icon} /> : null}

      {imageSrc ? (
        <div className={styles.image}>
          <img height={27} src={imageSrc} alt="header-logo" />
        </div>
      ) : null}

      <ClampedEllipsis
        style={titleStyle}
        className={cn(styles.title, className)}
        maxWidth={539}
        lineClamp={2}>
        {children}
      </ClampedEllipsis>
    </div>
  );
};

export default AccentIconTitle;
