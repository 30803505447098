import { ArrowLongIconDirectionEnum } from './types';

export const ArrowLongIcon = ({
  direction = ArrowLongIconDirectionEnum.right,
}: {
  direction?: ArrowLongIconDirectionEnum;
}) => {
  const leftDirectionPath =
    'M29 7C29.5523 7 30 7.44772 30 8C30 8.55228 29.5523 9 29 9L29 7ZM0.292893 8.7071C-0.0976311 8.31658 -0.097631 7.68341 0.292893 7.29289L6.65685 0.92893C7.04738 0.538406 7.68054 0.538406 8.07107 0.92893C8.46159 1.31945 8.46159 1.95262 8.07107 2.34314L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292893 8.7071ZM29 9L1 9L1 7L29 7L29 9Z';
  const rightDirectionPath =
    'M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM29.7071 8.7071C30.0976 8.31658 30.0976 7.68341 29.7071 7.29289L23.3431 0.92893C22.9526 0.538406 22.3195 0.538406 21.9289 0.92893C21.5384 1.31945 21.5384 1.95262 21.9289 2.34314L27.5858 8L21.9289 13.6569C21.5384 14.0474 21.5384 14.6805 21.9289 15.0711C22.3195 15.4616 22.9526 15.4616 23.3431 15.0711L29.7071 8.7071ZM1 9L29 9L29 7L1 7L1 9Z';

  const directions = {
    [ArrowLongIconDirectionEnum.left]: leftDirectionPath,
    [ArrowLongIconDirectionEnum.right]: rightDirectionPath,
  };

  return (
    <svg
      width="24"
      height="9"
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d={directions[direction]} fill="currentColor" />
    </svg>
  );
};
