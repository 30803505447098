import { Nullable } from 'maven-ui-kit/core/types';

import { LocalKey } from 'constants/local-storage';

export const loadState = <T>(name: LocalKey): Nullable<T> => {
  try {
    const serializedState = localStorage.getItem(name);

    return serializedState ? JSON.parse(serializedState) : null;
  } catch (err) {
    return null;
  }
};

export const saveState = <T>(name: LocalKey, state: T) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(name, serializedState);
  } catch {}
};

export const removeState = (name: LocalKey) => {
  localStorage.removeItem(name);
};
