import * as React from 'react';

import cn from 'classnames';

import AccentIcon from 'ui-kit/accent-icon';
import { LoaderIcon } from 'ui-kit/icons';

import styles from './styles.module.scss';

const Preloader: React.FC = () => {
  return (
    <div className={cn(styles.wrapper)}>
      <AccentIcon icon={<LoaderIcon />} />
    </div>
  );
};

export default Preloader;
