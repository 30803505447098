import * as React from 'react';

import Logo from 'components/logo';

import AccentIcon from 'ui-kit/accent-icon';
import Button from 'ui-kit/button';
import { TypeButton } from 'ui-kit/button/type';
import { ServiceUnavailableIcon } from 'ui-kit/icons';

import styles from './error-boundary-fallback.module.scss';

type Props = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};

const SUPPORT_EMAIL = 'support@maven.im';

const ErrorBoundaryFallback: React.FC<Props> = ({ eventId }: Props) => {
  const mailTo = `mailto:${SUPPORT_EMAIL}?subject=Ошибка 'Что-то пошло не так': ${
    eventId || ''
  }`;

  return (
    <div className={styles.wrapper}>
      <Logo />
      <div className={styles.contentWrapper}>
        <h1 className={styles.headline}>
          Что-то пошло не так, {'\n'}как было задумано
        </h1>
        <div className={styles.informationContainer}>
          <p className={styles.description}>
            Мы уже получили информацию об&nbsp;ошибке.
          </p>
          <p className={styles.description}>
            Если ошибка повторяется, пожалуйста, напишите нам на&nbsp;
            <a className={styles.a} href={mailTo}>
              {SUPPORT_EMAIL}
            </a>{' '}
            с&nbsp;подробностями.
          </p>
        </div>
        <Button href={mailTo} type={TypeButton.button}>
          Отправить письмо
        </Button>
        <div className={styles.imageWrapper}>
          <AccentIcon icon={<ServiceUnavailableIcon />} />
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
