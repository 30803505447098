export enum Names {
  home = 'home',
  presentations = 'presentations',
  presentation = 'presentation',
  slides = 'slides',
  clients = 'clients',
  client = 'client',
  organizations = 'organizations',
  organization = 'organization',
  segmentation = 'segmentation',
}

export const dynamicField = 'id';

export const dynamicParameter = `[${dynamicField}]`;

export enum Links {
  default = '/',
  home = '/home',
  organizations = '/home/organizations',
  clients = '/home/clients',
  presentations = '/presentations',
  segmentation = '/segmentation',
  slides = '/slides',
  test = '/test',
  test_result = '/test/result',
  alert = '/alert',
}

export const dynamicLinks = {
  [Names.presentation]: `${Links.presentations}/${dynamicParameter}`,
  [Names.segmentation]: `${Links.segmentation}/${dynamicParameter}`,
  [Names.slides]: `${Links.presentations}/${dynamicParameter}/${Names.slides}`,
  [Names.organization]: `${Links.organizations}/${dynamicParameter}`,
  [Names.client]: `${Links.clients}/${dynamicParameter}`,
};

export enum TypeLink {
  email = 'email',
  phone = 'phone',
}
