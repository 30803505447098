import { AllPsychotype } from 'ui-kit/icons/types';

import {
  SectionIcon__1_0,
  SectionIcon__1_1,
  SectionIcon__1_2,
  SectionIcon__1_3,
  SectionIcon__1_4,
  SectionIcon__1_5,
  SectionIcon__2_0,
  SectionIcon__2_1,
  SectionIcon__2_2,
  SectionIcon__2_3,
  SectionIcon__2_4,
} from './icon';
import { Question, Section } from './type';

const TEST_DATA: Section[] = [
  {
    title: 'Вы встречаете клиента в холле, перед кабинетом',
    text: 'Выберите наиболее подходящий ответ к каждому из следующих пяти факторов оценки',
    icon: SectionIcon__1_0,

    questions: [
      {
        title: 'Пунктуальность',
        icon: SectionIcon__1_1,

        buttons: [
          {
            text: 'Низкая',
            value: AllPsychotype.D,
          },
          {
            text: 'Крайне низкая',
            value: AllPsychotype.I,
          },
          {
            text: 'Высокая',
            value: AllPsychotype.S,
          },
          {
            text: 'Очень высокая',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Походка',
        icon: SectionIcon__1_2,

        buttons: [
          {
            text: 'Резкая, стремительная',
            value: AllPsychotype.D,
          },
          {
            text: 'Вальяжная, уверенная',
            value: AllPsychotype.I,
          },
          {
            text: 'Спокойная, не быстрая',
            value: AllPsychotype.S,
          },
          {
            text: 'Ровная, спокойная',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Жестикуляция',
        icon: SectionIcon__1_3,

        buttons: [
          {
            text: 'Активная, выраженная',
            value: AllPsychotype.D,
          },
          {
            text: 'Богатая, эмоциональная',
            value: AllPsychotype.I,
          },
          {
            text: 'Умеренная или отсутствует',
            value: AllPsychotype.S,
          },
          {
            text: 'Сдержанная или полностью отсутствует',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Общение с окружающими',
        icon: SectionIcon__1_4,

        buttons: [
          {
            text: 'Громкие, короткие фразы, часто перебивает собеседника',
            value: AllPsychotype.D,
          },
          {
            text: 'Умеренно громкий голос, желание понравиться собеседнику',
            value: AllPsychotype.I,
          },
          {
            text: 'Спокойная, правильная (литературная) речь',
            value: AllPsychotype.S,
          },
          {
            text: 'Не многословен, голос не повышает',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Стиль одежды',
        icon: SectionIcon__1_5,

        buttons: [
          {
            text: 'Удобная одежда, адаптация к условиям, одевается по случаю. Не придает большого значения одежде',
            value: AllPsychotype.D,
          },
          {
            text: 'Модная одежда, яркие образы, аксессуары, необычные детали, например, оранжевый галстук, яркая рубашка, большие цветные серьги. У женщин – яркий макияж',
            value: AllPsychotype.I,
          },
          {
            text: 'Неброская одежда, приглушенные цвета, стандартные фасоны, гармоничный вид',
            value: AllPsychotype.S,
          },
          {
            text: 'Чистая и выглаженная одежда, хороший вкус, подобранные цветовые сочетания, предпочтение – популярные бренды',
            value: AllPsychotype.C,
          },
        ],
      },
    ],
  },
  {
    title: 'В кабинете клиента',
    text: 'Выберите наиболее подходящий ответ к каждому из следующих четырёх факторов оценки',
    icon: SectionIcon__2_0,

    questions: [
      {
        title: 'Обстановка',
        icon: SectionIcon__2_1,

        buttons: [
          {
            text: 'Демонстрация высокого статуса: большое кресло, массивный стол, компьютер, бумаги, дорогие предметы интерьера.',
            value: AllPsychotype.D,
          },
          {
            text: 'Подчеркивание уникальности, творческий беспорядок. На столе лежат интересные вещицы, но бесполезные в работе.',
            value: AllPsychotype.I,
          },
          {
            text: 'Организованное рабочее место, без излишеств, возможны растения, фото семьи.',
            value: AllPsychotype.S,
          },
          {
            text: 'Абсолютный порядок. Только нужные и полезные предметы. Много необходимой рабочей информации, личное скрыто.',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Манера общения с представителем',
        icon: SectionIcon__2_2,

        buttons: [
          {
            text: 'Громкие, резкие вопросы, все предельно коротко, может одновременно работать с документами, компьютером, без излишней детализации.',
            value: AllPsychotype.D,
          },
          {
            text: 'Речь уверенная, желание понравиться собеседнику, эмоциональная окраска речи.',
            value: AllPsychotype.I,
          },
          {
            text: 'Интерес к собеседнику, внимание, доброжелательность, осторожность к новым предложениям.',
            value: AllPsychotype.S,
          },
          {
            text: 'Осторожность, скептицизм по отношению ко всему сказанному, сдержанность, краткость.',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Cпособ ответа на вопросы медпредставителя',
        icon: SectionIcon__2_3,

        buttons: [
          {
            text: 'Прямой, быстрый',
            value: AllPsychotype.D,
          },
          {
            text: 'Гибкий, не конкретный, эмоциональный',
            value: AllPsychotype.I,
          },
          {
            text: 'Уклончивый, многословный',
            value: AllPsychotype.S,
          },
          {
            text: 'Конкретный, краткий',
            value: AllPsychotype.C,
          },
        ],
      },
      {
        title: 'Отношение с коллегами',
        icon: SectionIcon__2_4,

        buttons: [
          {
            text: 'Требует безоговорочного выполнения всех указаний',
            value: AllPsychotype.D,
          },
          {
            text: 'Доверительное с элементами панибратства',
            value: AllPsychotype.I,
          },
          {
            text: 'Доброжелательно сдержанное',
            value: AllPsychotype.S,
          },
          {
            text: 'Уважительно официальное',
            value: AllPsychotype.C,
          },
        ],
      },
    ],
  },
];

export const questionsLength = TEST_DATA.reduce<Question[]>(
  (questions, section) => {
    return [...questions, ...section.questions];
  },
  [],
).length;

export default TEST_DATA;
