export const ClientIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.4 28.8a1 1 0 1 0 2 0h-2Zm19.444 0a1 1 0 1 0 2 0h-2Zm11.756 0a1 1 0 1 0 2 0h-2Zm-8.043-6.829a1 1 0 1 0 0 2v-2ZM3.4 28.8c0-.892.205-2.12.892-3.095.645-.917 1.797-1.733 3.987-1.733v-2c-2.784 0-4.572 1.088-5.623 2.582C1.648 25.988 1.4 27.674 1.4 28.8h2Zm19.444 0c0-1.223-.465-2.897-1.538-4.283-1.102-1.424-2.853-2.546-5.34-2.546v2c1.808 0 2.996.785 3.759 1.77.792 1.024 1.119 2.265 1.119 3.059h2Zm-6.878-6.829H8.279v2h7.687v-2ZM34.6 28.8c0-1.223-.465-2.897-1.537-4.283-1.103-1.424-2.853-2.546-5.34-2.546v2c1.807 0 2.996.785 3.758 1.77.793 1.024 1.12 2.265 1.12 3.059h2Zm-6.878-6.829h-3.165v2h3.165v-2Zm.583-7.07c0 1.545-1.265 2.811-2.844 2.811v2c2.667 0 4.844-2.146 4.844-4.81h-2Zm-2.844 2.811c-1.578 0-2.843-1.266-2.843-2.81h-2c0 2.664 2.176 4.81 4.843 4.81v-2Zm-2.843-2.81c0-1.545 1.265-2.812 2.843-2.812v-2c-2.667 0-4.843 2.146-4.843 4.811h2Zm2.843-2.812c1.579 0 2.844 1.267 2.844 2.811h2c0-2.665-2.177-4.81-4.844-4.81v2Zm-9.817 1.242c0 2.163-1.771 3.932-3.974 3.932v2c3.291 0 5.974-2.648 5.974-5.932h-2Zm-3.974 3.932c-2.203 0-3.974-1.769-3.974-3.932h-2c0 3.284 2.683 5.932 5.974 5.932v-2Zm-3.974-3.932c0-2.164 1.771-3.932 3.974-3.932v-2c-3.291 0-5.974 2.648-5.974 5.932h2ZM11.67 9.4c2.203 0 3.974 1.768 3.974 3.932h2c0-3.284-2.683-5.932-5.974-5.932v2Z"
      fill="#818BA7"
    />
  </svg>
);

export const ClientTitleIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.40537 21.9225C8.27247 23.2317 10.5465 24 13 24C19.3513 24 24.5 18.8513 24.5 12.5C24.5 6.14873 19.3513 1 13 1C6.64873 1 1.5 6.14873 1.5 12.5C1.5 16.3978 3.43916 19.8427 6.40537 21.9225ZM6.40537 21.9225C6.40537 18.2998 9.41928 17.3992 10.9262 17.4016H15.0738C16.5807 17.3992 19.5946 18.2998 19.5946 21.9225M16.582 10.2377C16.582 12.216 14.9783 13.8197 13 13.8197C11.0217 13.8197 9.41803 12.216 9.41803 10.2377C9.41803 8.25944 11.0217 6.65574 13 6.65574C14.9783 6.65574 16.582 8.25944 16.582 10.2377Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
