import * as React from 'react';

import cn from 'classnames';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import Button from 'ui-kit/button';
import { ButtonSize, TypeButton, VariantButton } from 'ui-kit/button/type';
import { ArrowLongIcon } from 'ui-kit/icons';
import { ArrowLongIconDirectionEnum } from 'ui-kit/icons/types';

import { BackButtonProps } from './type';

import styles from './back-button.module.scss';

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { className, to, onClick } = props;

  const router = useCustomRouter();

  const handleGoBack = () => {
    onClick?.();

    if (to) return router.push(to);

    router.back();
  };

  return (
    <Button
      size={ButtonSize.s}
      leftIcon={<ArrowLongIcon direction={ArrowLongIconDirectionEnum.left} />}
      variant={VariantButton.outlined}
      onClick={handleGoBack}
      className={cn(styles.root, className)}
      type={TypeButton.button}>
      Назад
    </Button>
  );
};

export default BackButton;
