import { NextRouter, useRouter } from 'next/router';

import { AllSort } from 'store/api/type';

export type CustomRouter = NextRouter & {
  query: QueryParams;
};

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const initialOrder = SortOrder.asc;

export interface QueryParams {
  id?: string;
  sort?: AllSort;
  order?: SortOrder;
  text?: string;
  email?: string;
  testSection?: number;
  testQuestion?: number;
  type?: string;
}

export const useCustomRouter = () => {
  const router: CustomRouter = useRouter();

  return router;
};
