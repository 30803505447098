import Image from 'next/image';
import { ChangeEvent, FC, useState } from 'react';
import { useDebounce } from 'react-use';

import cn from 'classnames';

import searchIcon from 'public/static/images/search.svg';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import { InputSearchProps } from './type';

import styles from './input-search.module.scss';

const InputSearch: FC<InputSearchProps> = (props) => {
  const { handleSearchCallback, placeholder = 'Поиск' } = props;

  const router = useCustomRouter();

  const { text = '' } = router.query;

  const [value, setValue] = useState(text);

  useDebounce(
    () => {
      handleSearchCallback(value);
    },
    500,
    [value],
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(value);
  };

  return (
    <div
      className={cn(
        'flex-row flex-align_center flex-justify_cb full-width',
        styles.searchContainer,
      )}>
      <div className={cn('flex-row', styles.searchIcon)}>
        <Image
          width={18}
          height={18}
          src={searchIcon}
          unoptimized
          alt="search-icon"
        />
      </div>

      <input
        placeholder={placeholder}
        onChange={onChange}
        type="search"
        value={value}
        className={cn('full-width', styles.searchInput)}
      />
    </div>
  );
};

export default InputSearch;
