import * as React from 'react';
import { useSelector } from 'react-redux';

import { getFullUrl } from 'maven-ui-kit/core';

import { OrganizationState } from 'reducers/organizationSlice';
import { RootState } from 'store';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import Logo from 'components/logo';

import AccentIconTitle from 'ui-kit/accent-icon-title';
import BackButton from 'ui-kit/back-button';

import { NAVIGATION_TABS } from 'data';

import styles from './header.module.scss';

const Header: React.FC = () => {
  const { pathname } = useCustomRouter();

  const { logos } = useSelector<RootState, OrganizationState>(
    ({ organization }) => organization,
  );

  const currentTab = NAVIGATION_TABS.find((tab) => tab.href === pathname);

  const shouldNavigateBack = pathname.split('/').length > 3;

  return (
    <header className={styles.header}>
      <div className={styles.headerTop}>
        {shouldNavigateBack ? (
          <BackButton />
        ) : (
          <React.Fragment>
            {logos ? (
              <AccentIconTitle imageSrc={getFullUrl(logos.mainLogo)}>
                {currentTab?.title}
              </AccentIconTitle>
            ) : (
              <AccentIconTitle icon={currentTab?.icon}>
                {currentTab?.title}
              </AccentIconTitle>
            )}
          </React.Fragment>
        )}

        <Logo />
      </div>
      {currentTab?.sortContainer}
    </header>
  );
};

export default Header;
