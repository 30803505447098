import { FC } from 'react';

import cn from 'classnames';

import { Content, IconProps, OrderButtonProps } from './type';

import styles from './sort-button.module.scss';

const OrderDateIcon: FC<IconProps> = (props) => {
  const { isRevert } = props;

  return (
    <svg
      className={cn({
        [styles.icon_mirror]: isRevert,
      })}
      width="12"
      height="9"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1H23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 9H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 17H11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const OrderButton: FC<OrderButtonProps> = (props) => {
  const { type, isRevert, ...buttonProps } = props;

  const content: Content = {
    date: <OrderDateIcon isRevert={isRevert} />,
    text: (
      <>
        {isRevert ? 'Я' : 'А'}
        <span>{isRevert ? '‹' : '›'}</span>
        {isRevert ? 'А' : 'Я'}
      </>
    ),
  };

  return (
    <button className={styles.button} {...buttonProps}>
      {content[type]}
    </button>
  );
};

export default OrderButton;
