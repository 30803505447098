import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import { CloseButton } from './controls';

import styles from './alert-info.module.scss';

const AlertInfo = () => {
  return (
    <ToastContainer
      className={styles.root}
      bodyClassName={styles.body}
      toastClassName={styles.toast}
      theme="colored"
      position="top-center"
      autoClose={8000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      draggableDirection="y"
      draggablePercent={70}
      pauseOnHover
      closeButton={CloseButton}
    />
  );
};

export default AlertInfo;
