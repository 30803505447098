import { GetClientOrganisationTypeAllResponse } from 'store/api/type';

import { Option } from 'ui-kit/select/type';

import { ClientOrganisationType } from 'types/data-types';

interface FetIsRetailProps {
  clientTypes?: GetClientOrganisationTypeAllResponse;
  type: Omit<ClientOrganisationType, 'noClient'>;
}

export const getIsRetail = (props: FetIsRetailProps) => {
  const { clientTypes, type } = props;

  const clientType = clientTypes?.find((clientType) => {
    return clientType.id === type.id;
  });

  const isRetail = clientType && clientType.noClient;

  return isRetail;
};

export const getTypeOptions = (data?: GetClientOrganisationTypeAllResponse) => {
  const types = data ?? [];

  const options: Option[] = types.map((type) => {
    const { id, name } = type;

    return {
      label: name,
      value: id,
    };
  });

  return options;
};
