import { AllPsychotype } from 'ui-kit/icons/types';

export interface Information {
  psychotypes: AllPsychotype[];
  colorName: string;
  indicator: string;
  getDescription: (className?: string) => JSX.Element;
  color?: string;
  svgPath?: string;
}

type PsychotypeInformation = {
  [k in AllPsychotype]: Information;
};

const psychotypeInformation: PsychotypeInformation = {
  [AllPsychotype.D]: {
    psychotypes: [AllPsychotype.D],
    colorName: 'Красный',
    indicator: 'Лидерство',
    getDescription: (className) => {
      return (
        <p className={className}>
          Характеристика
          <br />
          способности к&nbsp;руководству, <br />
          cамоутрверждению, <br /> лидерству.
        </p>
      );
    },
    color: '#E7374C',
    svgPath:
      'M8.645 8.14515C13.4121 3.37801 19.7166 0.502251 26.394 0V8.45774C21.9555 8.93562 17.7881 10.915 14.602 14.1012C11.4158 17.2874 9.43651 21.4548 8.95864 25.8935H0.5C1.00224 19.2169 3.87794 12.9112 8.645 8.1441V8.14515Z',
  },
  [AllPsychotype.I]: {
    psychotypes: [AllPsychotype.I],
    colorName: 'Жёлтый',
    indicator: 'Влияние',
    getDescription: (className) => {
      return (
        <p className={className}>
          Характеристика <br /> социальных связей <br />
          и&nbsp;коммуникабельности.
        </p>
      );
    },
    color: '#E5DF35',
    svgPath:
      'M6.02099 9.84052C4.28752 9.12211 2.46397 8.658 0.605469 8.45774V0C3.57124 0.223576 6.48827 0.916555 9.2453 2.05881C12.6519 3.47019 15.7469 5.53854 18.3545 8.14516C20.9621 10.7528 23.0305 13.8479 24.4408 17.2545C25.583 20.0106 26.2759 22.9277 26.4995 25.8945H18.0419C17.8417 24.036 17.3765 22.2124 16.6592 20.4789C15.6717 18.0938 14.2232 15.9279 12.3986 14.1022C10.574 12.2765 8.40717 10.8291 6.02205 9.84158L6.02099 9.84052Z',
  },
  [AllPsychotype.S]: {
    psychotypes: [AllPsychotype.S],
    colorName: 'Зелёный',
    indicator: 'Устойчивость',
    getDescription: (className) => {
      return (
        <p className={className}>
          Характеристика <br /> терпения, постоянства, <br /> заботы.
        </p>
      );
    },
    color: '#3BA48A',
    svgPath:
      'M16.6572 5.52109C17.3756 3.78758 17.8397 1.96401 18.04 0.105469H26.4976C26.274 3.07129 25.581 5.98838 24.4388 8.7444C23.0274 12.151 20.9591 15.2461 18.3526 17.8538C15.7449 20.4615 12.6499 22.5298 9.24334 23.9401C6.48737 25.0824 3.57034 25.7754 0.603516 25.9989V17.5412C2.46202 17.3409 4.28556 16.8758 6.01904 16.1584C8.40416 15.1709 10.5699 13.7224 12.3956 11.8978C14.2213 10.0731 15.6686 7.90625 16.6562 5.52109H16.6572Z',
  },
  [AllPsychotype.C]: {
    psychotypes: [AllPsychotype.C],
    colorName: 'Синий',
    indicator: 'Соответствие',
    getDescription: (className) => {
      return (
        <p className={className}>
          Характеристика <br /> касается структуры <br />
          и&nbsp;организованности.
        </p>
      );
    },
    color: '#3E69B7',
    svgPath:
      'M26.393 25.9989C23.4272 25.7754 20.5102 25.0824 17.7542 23.9401C14.3477 22.5288 11.2526 20.4604 8.645 17.8538C6.03737 15.2461 3.96907 12.151 2.55877 8.7444C1.41654 5.98838 0.723572 3.07129 0.5 0.105469H8.95758C9.15784 1.96401 9.62299 3.78758 10.3403 5.52109C11.3279 7.90625 12.7763 10.0721 14.6009 11.8978C16.4255 13.7224 18.5923 15.1709 20.9775 16.1584C22.7109 16.8768 24.5345 17.3409 26.393 17.5412V25.9989Z',
  },
  [AllPsychotype.DI]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.I],
    colorName: 'Красно-жёлтый',
    indicator: 'Вдохновитель',
    getDescription: (className) => {
      return (
        <p className={className}>
          Такая личность любит находиться среди людей, общаться, убеждать в
          чем-то
          <br />и перетягивать на свою сторону. Отличный дипломат, склонный к
          манипуляциям.
        </p>
      );
    },
  },
  [AllPsychotype.DS]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.S],
    colorName: 'Красно-зелёный',
    indicator: 'Упорный',
    getDescription: (className) => {
      return (
        <p className={className}>
          Наиболее сложная поведенческая модель. Таким людям свойственен
          &laquo;синдром <br /> отличника&raquo;, когда в&nbsp;любом деле важно
          стать лучшим. Подвержены перепадам <br /> настроения и&nbsp;излишней
          инициативности.
        </p>
      );
    },
  },
  [AllPsychotype.DC]: {
    psychotypes: [AllPsychotype.D, AllPsychotype.C],
    colorName: 'Красно-синий',
    indicator: 'Исполнитель',
    getDescription: (className) => {
      return (
        <p className={className}>
          Склонны к&nbsp;перфекционизму, агрессивны в&nbsp;процессе достижения
          цели.
          <br /> Любят изменения, быстро подстраиваются и&nbsp;усваивают
          новшества.
        </p>
      );
    },
  },
  [AllPsychotype.IS]: {
    psychotypes: [AllPsychotype.I, AllPsychotype.S],
    colorName: 'Жёлто-зелёный',
    indicator: 'Лёгкий в общении, внимательный, добрый',
    getDescription: (className) => {
      return (
        <p className={className}>
          Преданные друзьям и&nbsp;гибкие, когда случаются изменения. Любят
          стабильность, <br /> излишне доверчивы, избегают конфликтов.
        </p>
      );
    },
  },
  [AllPsychotype.CS]: {
    psychotypes: [AllPsychotype.C, AllPsychotype.S],
    colorName: 'Зелёно-синий',
    indicator: 'Координатор',
    getDescription: (className) => {
      return (
        <p className={className}>
          Надежный и&nbsp;усидчивый, на&nbsp;таких людей можно положиться. Любят
          стабильность
          <br /> и&nbsp;планирование.
        </p>
      );
    },
  },
  [AllPsychotype.CI]: {
    psychotypes: [AllPsychotype.C, AllPsychotype.I],
    colorName: 'Жёлто-синий',
    indicator: 'Противоречивый',
    getDescription: (className) => {
      return (
        <p className={className}>
          Сложное сочетание противоположных типов личности. Стремятся
          к&nbsp;успеху
          <br />и разделяют его с&nbsp;товарищами. Добиваются целей, попутно
          вдохновляя <br />
          на это других. Иногда нетерпимы, иногда чересчур эмоциональны.
        </p>
      );
    },
  },
};

// Список для отрисовки инонки психотипа

export const basicPsychotypes = [
  psychotypeInformation[AllPsychotype.D],
  psychotypeInformation[AllPsychotype.I],
  psychotypeInformation[AllPsychotype.C],
  psychotypeInformation[AllPsychotype.S],
];

// Список для описания психотипов

export const allPsychotypes = [
  psychotypeInformation[AllPsychotype.D],
  psychotypeInformation[AllPsychotype.I],
  psychotypeInformation[AllPsychotype.S],
  psychotypeInformation[AllPsychotype.C],
];

export default psychotypeInformation;
