import { CSSProperties, FC } from 'react';

import cn from 'classnames';

import { getLetters } from 'components/psychotype/helper';

import { basicPsychotypes } from 'data/psychotypes';

import { IconProps } from './types';

import styles from './segments-icons.module.scss';

export const SegmentIcon: FC<IconProps> = (props) => {
  const { isFullPsychotype = false, classnames, psychotypes } = props;

  return (
    <div className={cn(styles.fragments, classnames)}>
      {basicPsychotypes.map((psychotype) => {
        const { color, psychotypes: _psychotypes, svgPath } = psychotype;

        const style: CSSProperties = {
          fill: color,
        };

        const checkFillColor =
          psychotypes.some((psychotype) => psychotype === _psychotypes[0]) ||
          isFullPsychotype;

        return (
          <svg
            key={color}
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="26">
            <path
              d={svgPath}
              stroke={color}
              strokeWidth="1"
              fill="none"
              style={checkFillColor ? style : {}}
            />
          </svg>
        );
      })}
      <div className={styles.fragmentsLetter}>
        {basicPsychotypes.map((psychotype) => {
          const { color, psychotypes: _psychotypes } = psychotype;

          const isShowLetter = psychotypes.some(
            (psychotype) => psychotype === _psychotypes[0],
          );

          if (!isShowLetter) return null;

          return (
            <span
              key={color}
              style={{
                color,
              }}>
              {getLetters(_psychotypes)}
            </span>
          );
        })}
      </div>
    </div>
  );
};
