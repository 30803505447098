import * as React from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

import styles from './preloader.module.scss';

const Preloader: React.FC = () => (
  <svg className={styles.circular} viewBox="25 25 50 50">
    <motion.circle
      className={cn(styles.path)}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.28,
        duration: 0.2,
        ease: 'easeInOut',
      }}
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Preloader;
