import * as React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import cn from 'classnames';

import { toUpperFirstLetter } from 'utils/helper';

import DropdownIndicatorComponent from './components/DropdownIndicator';
import NoOptionsMessageComponent from './components/NoOptionsMessage';
import OptionComponent from './components/Option';
import { stylesSelect, stylesSortSelect } from './helper';
import { Option, SelectProps } from './type';

import styles from './select.module.scss';

// TODO Нормально типизировать forwardRef
const SelectComponent = React.forwardRef<never, SelectProps>(
  (
    {
      disabled = false,
      name = '',
      placeholder = '',
      description = '',
      options,
      label = '',
      onChange,
      onBlur,
      className = '',
      error = '',
      fieldState,
      defaultValue,
      isSort = false,
    },
    ref,
  ) => {
    const handleChange = (
      newValue: SingleValue<Option> | MultiValue<Option>,
    ) => {
      if (newValue) {
        const option = newValue as Option;

        onChange(option.value);
      }
    };

    if (isSort) {
      return (
        <Select
          defaultValue={defaultValue ? defaultValue : undefined}
          value={defaultValue ? defaultValue : undefined}
          isMulti={false}
          ref={ref}
          components={{
            DropdownIndicator: DropdownIndicatorComponent,
            NoOptionsMessage: NoOptionsMessageComponent,
            IndicatorSeparator: null,
            Option: OptionComponent,
          }}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          isDisabled={disabled}
          options={options}
          name={name}
          styles={stylesSortSelect}
        />
      );
    }

    const isError = !!error || !!fieldState?.error;
    const errorText = fieldState?.error?.message ?? error;

    return (
      <label
        className={cn(styles.label, {
          [className]: className,
          [styles.labelError]: !!fieldState?.error,
          [styles.labelValid]:
            (!fieldState?.error && fieldState?.isDirty) || defaultValue,
        })}>
        <span
          className={cn(styles.errorMessage, {
            [styles.messageShow]: isError,
          })}>
          {errorText}
        </span>

        <span
          className={cn(styles.description, {
            [styles.messageShow]: description,
          })}>
          {description}
        </span>

        <span
          className={cn(styles.labelTitle, {
            [styles.labelTitleDisabled]: disabled,
          })}>
          {label && toUpperFirstLetter(label)}
        </span>

        <Select
          defaultValue={defaultValue ? defaultValue : undefined}
          value={defaultValue ? defaultValue : undefined}
          isMulti={false}
          ref={ref}
          components={{
            DropdownIndicator: DropdownIndicatorComponent,
            NoOptionsMessage: NoOptionsMessageComponent,
            IndicatorSeparator: null,
            Option: OptionComponent,
          }}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          isDisabled={disabled}
          options={options}
          name={name}
          styles={stylesSelect}
        />
      </label>
    );
  },
);

SelectComponent.displayName = 'Select';

export default SelectComponent;
