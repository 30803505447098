import Image from 'next/image';
import { FC } from 'react';
import { CloseButtonProps } from 'react-toastify';

import { lexics } from 'data/lexics';

import { ALERT_STATUS_DATA } from './constants';
import { AlertType, CreateToastParams, NotificationProps } from './type';

import 'react-toastify/ReactToastify.css';
import styles from './alert-info.module.scss';

const Notification: FC<NotificationProps> = ({ header, message }) => (
  <>
    <p className={styles.alertText}>{header}</p>
    <p className={styles.message}>{message}</p>
  </>
);

export const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => (
  <button onClick={closeToast} className={styles.close}>
    Закрыть
  </button>
);

export const createToast = (
  type: AlertType,
  message?: string,
  params?: CreateToastParams,
) => {
  const defaultHeader = ALERT_STATUS_DATA[type].header;
  const defaultMessage = ALERT_STATUS_DATA[type].message;

  ALERT_STATUS_DATA[type].method(
    <Notification
      header={params?.header || defaultHeader}
      message={message || defaultMessage}
    />,
    {
      icon: () => (
        <Image
          src={ALERT_STATUS_DATA[type].icon}
          width={15}
          height={15}
          unoptimized
        />
      ),
    },
  );
};

export const createToastSuccess = (
  message?: string,
  params?: CreateToastParams,
) => createToast(AlertType.success, message, params);

export const createToastError = (
  message?: string,
  params?: CreateToastParams,
) =>
  createToast(
    AlertType.error,
    message || lexics.api.response.default.error,
    params,
  );

export const createToastInfo = (message?: string, params?: CreateToastParams) =>
  createToast(AlertType.info, message, params);
