export const ListCityIcon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33268 9.99935C5.92528 9.70305 7.11046 8.35491 7.11046 5.33268C7.11046 2.31046 5.92528 0.962312 5.33268 0.666016M5.33268 9.99935C4.74009 9.70305 3.5549 8.35491 3.5549 5.33268C3.5549 2.31046 4.74009 0.962312 5.33268 0.666016M5.33268 9.99935C7.28053 9.99935 8.94965 8.80597 9.64878 7.11046M5.33268 9.99935C3.38483 9.99935 1.71571 8.80597 1.01658 7.11046M5.33268 0.666016C7.28053 0.666016 8.94965 1.8594 9.64878 3.5549M5.33268 0.666016C3.38483 0.666016 1.71571 1.8594 1.01658 3.5549M9.64878 7.11046C9.87472 6.56253 9.99935 5.96216 9.99935 5.33268C9.99935 4.83496 9.92143 4.35544 9.77713 3.90565C9.73893 3.78658 9.69607 3.66959 9.64878 3.5549M9.64878 7.11046H1.01658M1.01658 7.11046C0.790648 6.56253 0.666016 5.96216 0.666016 5.33268C0.666016 4.83496 0.743934 4.35544 0.888238 3.90565C0.926439 3.78658 0.969292 3.66959 1.01658 3.5549M9.64878 3.5549H1.01658"
      stroke="currentColor"
    />
  </svg>
);

export const ListAddressIcon = () => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99935 1.5C2.44583 1.5 1.16602 2.7935 1.16602 4.41463C1.16602 5.41956 1.76601 6.7975 2.50581 7.97264C2.86833 8.54848 3.24636 9.04686 3.56569 9.3949C3.72637 9.57003 3.86242 9.69636 3.96607 9.77465C3.97813 9.78376 3.98921 9.79181 3.99935 9.79891C4.00948 9.79181 4.02057 9.78376 4.03262 9.77465C4.13628 9.69636 4.27233 9.57003 4.43301 9.3949C4.75233 9.04686 5.13037 8.54848 5.49288 7.97264C6.23269 6.7975 6.83268 5.41956 6.83268 4.41463C6.83268 2.7935 5.55287 1.5 3.99935 1.5ZM3.92233 9.84491C3.92235 9.84486 3.92344 9.84438 3.92554 9.84365C3.92336 9.84461 3.92231 9.84497 3.92233 9.84491ZM4.07316 9.84365C4.07526 9.84438 4.07635 9.84486 4.07637 9.84491C4.07639 9.84497 4.07534 9.8446 4.07316 9.84365ZM0.166016 4.41463C0.166016 2.26407 1.87097 0.5 3.99935 0.5C6.12773 0.5 7.83268 2.26407 7.83268 4.41463C7.83268 5.71649 7.09934 7.29789 6.33915 8.50541C5.95167 9.1209 5.53803 9.66968 5.16986 10.071C4.98679 10.2705 4.80513 10.4444 4.63535 10.5726C4.55049 10.6367 4.45884 10.6969 4.36343 10.7428C4.27339 10.7861 4.1462 10.8333 3.99935 10.8333C3.8525 10.8333 3.72531 10.7861 3.63526 10.7428C3.53985 10.6969 3.44821 10.6367 3.36335 10.5726C3.19357 10.4444 3.01191 10.2705 2.82884 10.071C2.46067 9.66968 2.04703 9.1209 1.65955 8.50541C0.899359 7.29789 0.166016 5.71649 0.166016 4.41463ZM3.99935 3.77642C3.67313 3.77642 3.38824 4.05073 3.38824 4.41463C3.38824 4.77854 3.67313 5.05285 3.99935 5.05285C4.32557 5.05285 4.61046 4.77854 4.61046 4.41463C4.61046 4.05073 4.32557 3.77642 3.99935 3.77642ZM2.38824 4.41463C2.38824 3.5213 3.09827 2.77642 3.99935 2.77642C4.90043 2.77642 5.61046 3.5213 5.61046 4.41463C5.61046 5.30797 4.90043 6.05285 3.99935 6.05285C3.09827 6.05285 2.38824 5.30797 2.38824 4.41463Z"
      fill="currentColor"
    />
  </svg>
);

export const ListPhoneIcon = () => (
  <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m6.781 6.629-.024.5h.024v-.5Zm-.303-2.36a.5.5 0 0 0 .98-.197l-.98.196Zm-.77-1.947a.5.5 0 1 0-.195.98l.196-.98ZM8.282 4.21a.5.5 0 0 0 .997-.077l-.997.077ZM5.649.503a.5.5 0 1 0-.077.997L5.65.503Zm2.784 8.535a.952.952 0 0 0 .556-.183.896.896 0 0 0 .357-.742h-1c0 .016-.003.007.008-.015a.138.138 0 0 1 .04-.047.07.07 0 0 1 .021-.012l.018-.001v1Zm.913-.925V7.054h-1V8.113h1Zm0-1.06a.982.982 0 0 0-.173-.552.878.878 0 0 0-.74-.372v1c.01 0-.005.001-.03-.012a.142.142 0 0 1-.049-.042c-.007-.01-.008-.016-.008-.014v-.008h1Zm-.913-.924H6.781v1H8.433v-1ZM.5 1.116c0 2.065.983 5.867 4.65 7.335l.372-.928C2.405 6.275 1.5 2.993 1.5 1.116h-1ZM5.15 8.45c.93.372 2.018.587 3.283.587v-1c-1.15 0-2.111-.195-2.911-.515l-.372.928ZM6.806 6.13c-.555-.028-1.382.424-1.927 1.655l.914.405c.446-1.007.968-1.061.964-1.062l.049-.998Zm-5.16-1.334a.846.846 0 0 0 .348-.067c.088-.035.179-.082.265-.133.173-.103.363-.241.54-.396.177-.154.355-.337.494-.532.129-.18.272-.439.272-.73h-1c0-.023.003.024-.087.15-.08.113-.198.238-.336.359-.137.119-.278.22-.392.288a.965.965 0 0 1-.125.064c-.04.016-.006-.006.07-.002l-.05.999Zm1.919-1.858V1.116h-1V2.939h1Zm0-1.822a.982.982 0 0 0-.173-.553.878.878 0 0 0-.74-.372v1c.009 0-.006.002-.03-.011a.142.142 0 0 1-.05-.043c-.007-.01-.008-.016-.007-.013v-.008h1ZM1.413.19a.952.952 0 0 0-.555.183.896.896 0 0 0-.358.742h1c0-.016.003-.007-.008.015a.137.137 0 0 1-.04.047.07.07 0 0 1-.02.012l-.019.001v-1Zm0 1h1.239v-1H1.413v1Zm6.046 2.881c-.097-.483-.57-1.514-1.75-1.75l-.196.98c.63.127.911.695.965.966l.98-.196Zm1.819.06a4.26 4.26 0 0 0-.83-2.147C7.881 1.235 6.982.605 5.65.503l-.077.997c1.022.079 1.668.544 2.078 1.087.423.56.6 1.212.63 1.622l.998-.077Z"
      fill="currentColor"
    />
  </svg>
);

export const ListEmailIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99999 4.77691H1.47508M1.47508 4.77691C0.73945 6.73107 0.555542 7.91045 0.555542 8.25586C0.555542 8.90724 1.0664 9.02074 1.32183 8.99607C2.73179 8.99607 9.44443 5.49825 9.44443 4.77691C9.44443 4.05557 2.73179 0.557752 1.32183 0.557752C1.0664 0.533078 0.555542 0.646576 0.555542 1.29795C0.555542 1.64337 0.73945 2.82275 1.47508 4.77691Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export const ListPositionIcon = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5555 9.00087C10.5555 8.25279 10.1111 6.75664 8.33334 6.75664H5.88885C4.11108 6.75664 3.66664 8.25279 3.66664 9.00087M2.25924 6.45741V3.46511M3.74071 4.96126H0.777771M8.99993 3.3155C8.99993 4.34838 8.17084 5.18569 7.1481 5.18569C6.12535 5.18569 5.29626 4.34838 5.29626 3.3155C5.29626 2.28262 6.12535 1.44531 7.1481 1.44531C8.17084 1.44531 8.99993 2.28262 8.99993 3.3155Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

export const ListMobilePhoneIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.86551 8.88402C2.40688 7.49941 1.44446 3.88776 1.44446 1.87775C1.44446 1.5318 1.72516 1.44531 1.86551 1.44531H3.12867C3.46551 1.44531 3.54972 1.7336 3.54972 1.87774C3.54972 1.87774 3.54972 3.19037 3.54972 3.73634C3.54972 4.2823 2.4088 5.13483 2.1281 5.12099M5.86551 8.88402C6.74738 9.23706 7.79154 9.44531 9.02341 9.44531C9.16376 9.44531 9.44446 9.35883 9.44446 9.01288C9.44446 8.66693 9.44446 8.14802 9.44446 7.9318C9.44446 7.78766 9.36025 7.49937 9.02341 7.49937C8.68656 7.49937 7.76025 7.49937 7.33919 7.49937C7.05849 7.48553 6.37077 7.74309 5.86551 8.88402Z"
      stroke="currentColor"
    />
  </svg>
);
