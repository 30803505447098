export const LoaderIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="150px"
    height="150px"
    viewBox="0 0 150 150"
    enableBackground="new 0 0 150 150"
    xmlSpace="preserve">
    <g id="Layer_1">
      <circle
        opacity="0.4"
        fill="#FFFFFF"
        stroke="#9DEDFF"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeMiterlimit="10"
        cx="75"
        cy="75.293"
        r="48.707"
      />
    </g>
    <g id="Layer_2">
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="36.2957"
          y1="34.8138"
          x2="94.5114"
          y2="34.8138">
          <stop offset="0" />
          <stop offset="1" />
        </linearGradient>
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          d="M38.296,43.227
			c0,0,21.86-26.035,54.216-13.336">
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 75 75"
            to="-360 75 75"
            dur=".8s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);
