import * as React from 'react';
import { useDebounce } from 'react-use';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import { lexics } from 'data/lexics';

import styles from './noAccess.module.scss';

const { access } = lexics;

const NoAccess: React.FC = () => {
  const { push } = useCustomRouter();

  useDebounce(
    () => {
      push(`${process.env.NEXT_PUBLIC_ID_URL}`);
    },
    5000,
    [],
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {access.title}
        <br />
        {access.description}
      </h1>
    </div>
  );
};

export default NoAccess;
